import { THubService } from './../../../core/service/tHubService/t-hub.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BottomNavBarService } from '../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../../core/service/header/header.service';
import { LoaderService } from '../../../core/service/loader/loader.service';

@Component({
  selector: 'app-t-hub-review',
  templateUrl: './t-hub-review.component.html',
  styleUrl: './t-hub-review.component.scss',
})
export class THubReviewComponent implements OnInit {
  feedbackForm!: FormGroup;
  checkBoxOptions = [
    'Event Room Booking Experience',
    'Security Check-in',
    'Event Venue Ambiance',
    'Seating Arrangement',
    'Audio/Visual System',
    'Housekeeping (Event Room/Washroom)',
    'Food Service',
    'Overall Experience',
  ];
  selectedOptions: string[] = [];
  verifyUserNameResponseMessage!: String;

  constructor(
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private tHubService: THubService,
    private readonly headerService: HeaderService,
    private readonly bottomNavService: BottomNavBarService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.headerService.hide();
    this.bottomNavService.hide();
    this.feedbackForm = this.reviewForm();
  }

  goBack() {
    this.location.back();
  }

  reviewForm() {
    return this.fb.group({
      rating: [null, Validators.required],
      name: ['', Validators.required],
      mobileNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
      email: ['', [Validators.required, Validators.email]],
      eventName: ['', Validators.required],
      additionalComments: [''],
    });
  }

  setRating(value: number) {
    this.feedbackForm.get('rating')?.setValue(value);
  }

  onCheckBoxChange(event: any, option: string) {
    if (event.target.checked) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (item) => item !== option,
      );
    }
  }

  isFormValid(): boolean {
    return this.feedbackForm.valid && this.selectedOptions.length > 0;
  }

  submitForm() {
    if (this.isFormValid()) {
      this.loaderService.show();
      const formData = {
        ...this.feedbackForm.value,
        checkBoxes: this.selectedOptions,
      };

      this.tHubService.postReview(formData).subscribe({
        next: (res: any) => {
          if (res.body.responseCode == 201) {
            this.selectedOptions = [];
            this.feedbackForm.reset();
            this.verifyUserNameResponseMessage = res.body.responseMessage;
            this.router.navigate(['/t-hub-congrats'], { replaceUrl: true });
            this.loaderService.hide();
          }
        },
        error: (res) => {
          this.verifyUserNameResponseMessage = res.error.responseMessage;
          this.loaderService.hide();
        },
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class THubService {
  private api = AppConfig.getAPIURI() + 'thub-review';

  constructor(private http: HttpClient) {}

  postReview(requestBody: any): Observable<any> {
    return this.http.post(this.api + '/save-review', requestBody, {
      observe: 'response',
    });
  }
  getThubReviews(rating: any): Observable<any> {
    return this.http.get(this.api + '/fetch-review/' + `${rating}`, {
      observe: 'response',
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BottomNavBarService } from '../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../../core/service/header/header.service';
import { DataTransferService } from 'src/app/core/service/data-transfer/data-transfer.service';
@Component({
  selector: 'app-t-hub-landing',
  templateUrl: './t-hub-landing.component.html',
  styleUrl: './t-hub-landing.component.scss',
})
export class THubLandingComponent implements OnInit {
  wifi: any;
  constructor(
    public router: Router,
    private readonly headerService: HeaderService,
    private readonly bottomNavService: BottomNavBarService,
    private readonly dataTransferService: DataTransferService,
  ) {}
  ngOnInit(): void {
    this.headerService.hide();
    this.bottomNavService.hide();
    this.wifi = this.dataTransferService?.getWifiDetails();
  }
  navigateToReview() {
    this.router.navigate(['t-hub-review']);
  }
  navigateToThubEvent() {
    window.open('https://t-hub.co/host-an-event/', '_blank');
  }

  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert(`Copied: ${text}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }
}

<div
  class="responsive-w responsive-h d-flex justify-content-center align-items-center"
>
  <div>
    <img
      src="../../../../assets/images/thub_review_success.svg"
      alt="T hub review success"
    />
    <p class="text-center fs-23 fw-600 pt-3">Thank You for your feedback</p>
  </div>
</div>

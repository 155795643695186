import { Icon } from './../../core/interfaces/icon';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { HeaderService } from './../../core/service/header/header.service';
import { NotificationService } from './../../core/service/notificationService/notification.service';
import { WebSocketService } from './../../core/service/websocketService/websocket.service';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
})
export class SideNavBarComponent implements OnInit {
  userProfileImage: any;
  profile: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  unseenNotifications!: any;
  platformId: Object;
  icons: any[] = [];
  business: any;
  brandDetails: any;
  hideScream: any;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  getBusinessImage: any;

  userIcons = [
    {
      iconName: 'home ',
      content: 'Home ',
      routerLink: '/home',
    },
    {
      iconName: 'account_circle',
      content: 'My account',
      routerLink: '/account',
    },
    {
      iconName: 'notifications',
      content: 'Notifications',
      routerLink: '/notification',
    },
  ];
  businessIcons = [
    {
      iconName: 'home',
      content: 'Home',
      routerLink: '/business/home',
    },
    {
      iconName: 'dashboard',
      content: 'Dashboard',
      routerLink: '/business/dashboard',
    },

    {
      iconName: 'notifications',
      content: 'Notifications',
      routerLink: '/business/notification',
    },
    {
      iconName: 'military_tech',
      content: 'Get Premium',
      routerLink: '/business/get-premium',
    },
    {
      iconName: 'event',
      content: 'Events',
      routerLink: '/business/events',
    },
    {
      iconName: 'local_dining',
      content: 'Add Menu',
      routerLink: '/business/menu',
    },
    {
      iconName: 'settings',
      content: 'Settings',
      routerLink: '/business/account',
    },
  ];

  constructor(
    public headerService: HeaderService,
    public router: Router,
    public readonly dataTransferService: DataTransferService,
    private notificationService: NotificationService,
    private webSocketService: WebSocketService,
    @Inject(PLATFORM_ID) platformId: Object,
    public dialog: MatDialog,
  ) {
    this.platformId = platformId;
  }
  ngOnInit(): void {
    this.getBusinessImage = this.dataTransferService.getBusinessImage();
    this.headerService.imageSubject$.subscribe((imageUrl) => {
      this.getBusinessImage = this.blobStorageLink + imageUrl;
    });
    this.hideScream = this.dataTransferService.isBusinessLoggedin();
    if (this.hideScream) {
      const brandDetails = this.dataTransferService.getBrandDetails();
      this.business = brandDetails ? JSON.parse(brandDetails) : null;
      this.icons = this.businessIcons;
    } else {
      this.icons = this.userIcons;
      const userDetails = this.dataTransferService.getUserDetails();
      this.profile = userDetails ? JSON.parse(userDetails) : null;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.profile = JSON.parse(
        this.dataTransferService.getUserDetails() || '',
      );
    }
    this.headerService.fullNameSubject$?.subscribe((fullName) => {
      this.profile.fullName = fullName;
    });
    this.headerService.imageSubject$?.subscribe((imageUrl) => {
      this.userProfileImage = imageUrl;
    });
    this.userProfileImage = this.dataTransferService.getEditProfileImage();

    if (this.dataTransferService.isLoggedin()) {
      this.webSocketService.updateNotificationSubject$?.subscribe(() => {
        this.notificationService
          .fetchNotificationsCount()
          .subscribe((response) => {
            this.unseenNotifications = response.body?.responseBody;
          });
      });
    } else if (this.dataTransferService.isBusinessLoggedin()) {
      this.webSocketService.updateBrandNotificationSubject$?.subscribe(() => {
        this.notificationService
          .fetchBrandNotificationsCount()
          .subscribe((response) => {
            this.unseenNotifications = response.body?.responseBody;
          });
      });
    }
  }
  isCurrentRouteContains(substring: string): boolean {
    return this.router.url.includes(substring);
  }
  isDashboardOrPaymentHistory(): boolean {
    return (
      this.isCurrentRouteContains('/business/dashboard') ||
      this.isCurrentRouteContains('/business/payment-history')
    );
  }

  openScreamDialog() {
    this.router.url?.replace(/(\?|\&)qrCode=true/, '');
    this.router.navigate([], {
      queryParams: { scream_it: 'true' },
      queryParamsHandling: this.router.url?.includes('qrCode') ? null : 'merge',
    });
  }

  goToMyProfile() {
    this.router.navigate(['/my-profile']);
    window.scroll(0, 0);
  }

  goToPageNotFound() {
    this.router.navigate(['/page-not-found']);
    window.scroll(0, 0);
  }

  goToBusinessProfile() {
    this.router.navigate(['/business/profile']);
    window.scroll(0, 0);
  }

  goToScream() {
    this.router.navigate(['/scream/scream-it']);
  }

  goToCategories() {
    this.router.navigate(['/categories']);
  }

  isCurrentRoute(route: string): boolean {
    return this.router.isActive(route, true);
  }

  updateNotifications() {
    if (this.dataTransferService.isLoggedin()) {
      this.notificationService.markAsAllRead().subscribe((response: any) => {
        if (response.responseCode == 200) {
          this.unseenNotifications = 0;
        }
      });
    } else if (this.dataTransferService.isBusinessLoggedin()) {
      this.notificationService
        .markAsAllBrandRead()
        .subscribe((response: any) => {
          if (response.responseCode == 200) {
            this.unseenNotifications = 0;
          }
        });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

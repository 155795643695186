import { Component, OnInit } from '@angular/core';
import { BottomNavBarService } from '../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../../core/service/header/header.service';

@Component({
  selector: 'app-t-hub-congrats',
  templateUrl: './t-hub-congrats.component.html',
  styleUrl: './t-hub-congrats.component.scss',
})
export class THubCongratsComponent implements OnInit {
  constructor(
    private readonly headerService: HeaderService,
    private readonly bottomNavService: BottomNavBarService,
  ) {}

  ngOnInit(): void {
    this.headerService.hide();
    this.bottomNavService.hide();
  }
}

import { SubCategory } from './../../../core/interfaces/sub-category';
import { Component, OnInit } from '@angular/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { Category } from '../../../core/interfaces/category';
import { DataTransferService } from '../../../core/service/data-transfer/data-transfer.service';
import { HeaderService } from '../../../core/service/header/header.service';
import { SnackbarService } from '../../../core/service/snackBar/snackbar.service';
import { StorageBlobService } from '../../../core/service/storageBlobService/storage-blob.service';
import { BrandService } from '../../../core/service/brandService/brand.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../../../core/service/loader/loader.service';
import { CategoryService } from '../../../core/service/categories/category.service';
@Component({
  selector: 'app-edit-business-details',
  templateUrl: './edit-business-details.component.html',
  styleUrls: ['./edit-business-details.component.scss'],
})
export class EditBusinessDetailsComponent implements OnInit {
  categories: Category[] = [];
  subCategories: SubCategory[] = [];
  editProfile!: FormGroup;
  blobStorageLink = this.dataTransferService.blobUrl;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  orientation!: DOC_ORIENTATION;
  imageKey: any;
  imageUrl: any;
  parsedBrandDetails: any;
  businessProfileImage: any;
  errorMessage: any;
  isImageUpdated!: boolean;
  uploadedImage!: string;
  formData!: FormData;
  selectedImage!: string;

  constructor(
    private dataTransferService: DataTransferService,
    private storageBlobService: StorageBlobService,
    private snackBarService: SnackbarService,
    public imageCompress: NgxImageCompressService,
    public readonly headerService: HeaderService,
    private brandService: BrandService,
    public loaderService: LoaderService,
    private readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.editProfile = this.editProfileForm();
    this.fetchCategories();
    this.fetchBusinessDetails();
    this.onChangeCategory();
  }

  editProfileForm(): FormGroup {
    return new FormGroup({
      brandName: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      websiteLink: new FormControl(''),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10,15}$'),
      ]),
      category: new FormControl('', [Validators.required]),
      subCategory: new FormControl('', [Validators.required]),
    });
  }

  fetchBusinessDetails() {
    this.brandService.fetchBrandDetails()?.subscribe({
      next: (response: any) => {
        if (response.body.responseCode == 200) {
          this.editProfile.controls['brandName']?.setValue(
            response.body.responseBody.brandName,
          );
          this.editProfile.controls['email']?.setValue(
            response.body.responseBody.brandDetails?.email,
          );
          this.editProfile.controls['websiteLink']?.setValue(
            response.body.responseBody.brandDetails?.websiteLink,
          );
          this.editProfile.controls['mobileNumber']?.setValue(
            response.body.responseBody.businessAddress?.mobileNumber,
          );
          this.editProfile.controls['category']?.setValue(
            response.body.responseBody.subCategory?.category?.id,
          );
          this.editProfile.controls['subCategory']?.setValue(
            response.body.responseBody.subCategory?.id,
          );
        }
        this.imageKey = response.body.responseBody.logoImage;
        this.fetchBrandImage(this.imageKey);
      },
      error: (data) => {
        this.errorMessage = data.error?.responseMessage;
      },
    });
  }

  fetchCategories() {
    this.categoryService.getCategories().subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 200) {
          let categoriesList = data.body.responseBody;
          this.categories = categoriesList;
        }
      },
      error: (data) => {
        this.errorMessage = data.error?.responseMessage;
      },
    });
  }

  fetchSubCategories(value: Number) {
    this.categoryService.getSubCategories(value).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 200) {
          let categoriesList = data.body.responseBody;
          this.subCategories = categoriesList;
        }
      },
      error: (data: any) => {
        this.errorMessage = data.error?.responseMessage;
      },
    });
  }

  onChangeCategory() {
    this.editProfile.controls['category'].valueChanges.subscribe(
      (value: any) => {
        if (value) {
          this.editProfile?.controls['subCategory'].setValue(null);
          this.fetchSubCategories(value);
        }
      },
    );
  }

  onSelect(event: any) {
    if (event.target.files) {
      let file = event.target.files[0];
      if (
        !file.type ||
        (file.type !== 'image/jpeg' && file.type !== 'image/png')
      ) {
        this.snackBarService.openSnackBar(
          'Only jpg/jpeg & png files are allowed!',
          'error-snackbar',
        );
        return;
      }
      const trimmedFileName = file.name.replace(/\s/g, '');
      const trimmedFile = new File([file], trimmedFileName, {
        type: file.type,
      });
      if (
        trimmedFile &&
        (trimmedFile.type == 'image/jpeg' || trimmedFile.type == 'image/png')
      ) {
        this.isImageUpdated = true;
        var reader = new FileReader();
        reader.readAsDataURL(trimmedFile);
        reader.onload = (event: any) => {
          this.selectedImage = event.target.result;
          this.editProfile.patchValue({ logoImage: trimmedFile });
          if (trimmedFile.size <= 1024 * 1024) {
            this.formData = new FormData();
            this.formData.append('file', trimmedFile);
          } else {
            this.imageCompress
              .compressFile(event.target.result, this.orientation, 50, 50)
              .then((result: any) => {
                const compressedFile = this.dataURItoBlob(result) as File;
                this.formData = new FormData();
                this.formData.append('file', compressedFile);
              });
          }
        };
      }
    }
  }

  dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  updateBrandBody() {
    return {
      logoImage: this.isImageUpdated ? this.uploadedImage : this.imageKey,

      brandDetails: { websiteLink: this.editProfile.value['websiteLink'] },
      subCategory: {
        id: this.editProfile.value['subCategory'],
      },
      category: {
        id: this.editProfile.value['category'],
      },
      businessAddress: {
        mobileNumber: this.editProfile.value['mobileNumber'],
      },
    };
  }

  localStorageBody(body: any) {
    return {
      logoImage: body.logoImage,
      websiteLink: body.brandDetails.websiteLink,
      mobileNumber: body.businessAddress.mobileNumber,
      subCategoryId: body.subCategory.id,
      categoryId: body.category.id,
    };
  }
  update() {
    if (this.editProfile.valid) {
      this.loaderService.show();
      let brandDetailsString = this.dataTransferService.getBrandDetails();
      let existingBrandDetails = brandDetailsString
        ? JSON.parse(brandDetailsString)
        : {};
      const updatedBrandDetails = this.localStorageBody(this.updateBrandBody());

      const mergedBrandDetails = {
        ...existingBrandDetails,
        ...updatedBrandDetails,
      };
      this.brandService.setBrandDetails(JSON.stringify(mergedBrandDetails));
      this.brandService.updateBrand(this.updateBrandBody())?.subscribe({
        next: (data: any) => {
          if (data.body.responseCode == 201) {
            this.loaderService.hide();
            this.snackBarService.openSnackBar(
              data.body.responseMessage,
              'success-snackbar',
            );
            this.fetchBusinessDetails();
          }
        },
        error: (data) => {
          this.loaderService.hide();
          if (data.status == 0) {
            this.snackBarService.openSnackBar(
              'Internal server error. Please try again!',
              'error-snackbar',
            );
          } else {
            this.snackBarService.openSnackBar(
              data.error?.responseMessage,
              'error-snackbar',
            );
          }
        },
      });
    }
  }

  uploadImage() {
    if (this.isImageUpdated) {
      this.storageBlobService
        .uploadImage(this.formData)
        .subscribe((data: any) => {
          this.uploadedImage = data.body.responseBody[0];
          this.update();
          this.isImageUpdated = false;
          this.setEditProfileImage(this.uploadedImage);
        });
    }
  }

  getProfileImage(): string {
    if (this.uploadedImage) {
      return this.imageKey;
    } else {
      return this.imageUrl;
    }
  }

  fetchBrandImage(imageKey: string | null) {
    if (imageKey != null) {
      this.imageUrl = imageKey;
    } else {
      this.imageUrl = null;
    }
    if (this.imageUrl != null) {
      this.setEditProfileImage(this.imageUrl);
    }
  }

  setEditProfileImage(imageUrl: any) {
    const brandDetailsString = this.dataTransferService.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString);
      brandDetails.logoImage = imageUrl;
      this.dataTransferService.setBrandDetails(JSON.stringify(brandDetails));
      this.headerService.updateImage(imageUrl);
    }
  }

  isFormModified() {
    let brandDetails: any;
    const brandDetailsString = this.dataTransferService.getBrandDetails();
    if (brandDetailsString) {
      brandDetails = JSON.parse(brandDetailsString);
    }
    return (
      this.editProfile?.value['websiteLink'] != brandDetails?.websiteLink ||
      this.editProfile?.value['mobileNumber'] != brandDetails?.mobileNumber ||
      this.editProfile?.value['category'] != brandDetails?.categoryId ||
      this.editProfile?.value['subCategory'] != brandDetails?.subCategoryId ||
      this.isImageUpdated
    );
  }

  isFormValid(): boolean {
    return (
      this.isFormModified() &&
      this.editProfile.valid &&
      !this.editProfile.errors
    );
  }
}

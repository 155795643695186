<div class="p-3">
  <div class="d-grid py-3 gap-4">
    <div class="text-center">
      <img
        src="../../../../assets/images/T-Hub_Logo-PNG.png"
        alt="T hug logo"
        class="img-fluid dimension-80"
      />
    </div>
    <div class="card rounded-20 p-4 pointer">
      <div class="d-flex justify-content-center align-items-center">
        <img
          src="/assets/images/connect-to-wifi.svg"
          alt="t-hub feedback illustration"
        />
      </div>
      <h3
        class="d-flex justify-content-center align-items-center fs-23 fw-600 black-color pt-4 m-0"
      >
        Connect to Wifi
      </h3>
      <h4
        class="d-flex justify-content-center align-items-center fs-18 fw-500 pt-4 m-0"
      >
        User name: <strong class="mx-1">{{ wifi?.name }}</strong>
        <span
          class="material-icons copy-icon"
          (click)="copyToClipboard(wifi?.name)"
          >content_copy</span
        >
      </h4>

      <h4
        class="d-flex justify-content-center align-items-center fs-18 fw-500 pt-2 m-0"
      >
        Password: <strong class="mx-1">{{ wifi?.password }}</strong>
        <span
          class="material-icons copy-icon"
          (click)="copyToClipboard(wifi?.password)"
          >content_copy</span
        >
      </h4>
    </div>
    <div class="card rounded-20 p-4 pointer" (click)="navigateToReview()">
      <div class="d-flex justify-content-center align-items-center">
        <img
          src="/assets/images/feedback.png"
          alt="t-hub feedback illustration"
        />
      </div>
      <h3
        class="d-flex justify-content-center align-items-center fs-23 fw-600 black-color pt-4 m-0"
      >
        Feedback
        <span class="material-symbols-outlined ps-3 black-color">
          arrow_forward
        </span>
      </h3>
    </div>
    <div class="card rounded-20 p-4 pointer" (click)="navigateToThubEvent()">
      <div class="d-flex justify-content-center align-items-center">
        <img
          src="/assets/images/t-hub event.svg"
          alt="t-hub event illustration"
        />
      </div>
      <h3
        class="d-flex justify-content-center align-items-center fs-23 fw-600 black-color pt-4 m-0"
      >
        Host An Event
        <span class="material-symbols-outlined ps-3 black-color">
          arrow_forward
        </span>
      </h3>
    </div>
  </div>
</div>

<div
  class="width mt-6 py-3 px-2 p-lg-2 d-flex flex-column fixed-container"
  [ngClass]="{
    'left-col': isCurrentRouteContains('/business'),
    'left-col-dashboard': isDashboardOrPaymentHistory(),
  }"
>
  <div
    class="container icon-ripple-size side-nav-item d-flex align-items-center pointer p-2 mb-3 p-lg-2"
    (click)="!hideScream ? goToMyProfile() : goToBusinessProfile()"
  >
    <div>
      <img
        class="background-white-color profile-image-40 rounded-object profile-border {{
          hideScream === true ? 'object-contain' : ''
        }}"
        [src]="
          hideScream === false
            ? userProfileImage !== null
              ? blobStorageLink + userProfileImage
              : defaultImage
            : hideScream === true
              ? getBusinessImage
              : null
        "
        alt="profile"
      />
    </div>

    <p
      class="name-wrap fs-18 fw-400 lato-font-family display-none m-0 ps-lg-4"
      [class.active-link]="
        isCurrentRoute('/my-profile') || isCurrentRoute('/business/profile')
      "
    >
      {{ profile?.fullName || business?.brandName }}
    </p>
    <span
      class="material-icons active-link verified fs-16 px-1"
      [ngClass]="this.dataTransferService.setVerificationIconColor()"
      *ngIf="business?.documentVerificationStatus === 'VERIFIED'"
    >
      verified
    </span>
  </div>
  <div *ngFor="let icon of icons">
    <div
      class="container icon-ripple-size content-center side-nav-item d-flex align-items-center pointer p-2 my-3 p-lg-2"
      (click)="scrollToTop()"
      *ngIf="hideMenu(icon)"
      [routerLink]="icon.routerLink"
      [class.active-link]="
        isCurrentRoute(icon.routerLink) || isReviewsPage(icon)
      "
      (click)="handleNavigation(icon)"
    >
      <mat-icon
        class="material-icons-outlined fs-32 icon-size"
        [class.active-link]="
          isCurrentRoute(icon.routerLink) || isReviewsPage(icon)
        "
        *ngIf="icon.iconName !== 'notifications'"
      >
        {{ icon.iconName }}
      </mat-icon>
      <mat-icon
        class="material-icons-outlined fs-32 icon-size"
        [class.active-link]="
          isCurrentRoute(icon.routerLink) || isReviewsPage(icon)
        "
        *ngIf="icon.iconName === 'notifications'"
        (click)="updateNotifications()"
        matBadge="{{ unseenNotifications }}"
        matBadgeHidden="{{ unseenNotifications === 0 }}"
      >
        {{ icon.iconName }}
      </mat-icon>
      <p
        class="fs-18 fw-400 lato-font-family display-none m-0 ps-lg-4"
        [class.active-link]="isCurrentRoute(icon.routerLink)"
        (click)="updateNotifications()"
        *ngIf="icon.content === 'Notifications'"
      >
        {{ icon.content }}
      </p>
      <p
        class="fs-18 fw-400 lato-font-family display-none m-0 ps-lg-4"
        [class.active-link]="isCurrentRoute(icon.routerLink)"
        *ngIf="icon.content !== 'Notifications'"
      >
        {{ icon.content }}
      </p>
    </div>
  </div>

  <div
    *ngIf="!hideScream"
    class="container icon-ripple-size d-flex align-items-center mt-3 p-2 d-lg-none"
    (click)="openScreamDialog()"
  >
    <button mat-mini-fab color="none">
      <img
        class="icon-size"
        src="assets/images/fabIconThunders.svg"
        alt="scremer-thunder"
      />
    </button>
  </div>
  <div
    *ngIf="!hideScream"
    class="d-flex align-items-center pointer display-none mt-3 p-lg-2"
    (click)="openScreamDialog()"
  >
    <button mat-raised-button class="w-100 scream-btn-shadow h-48 rounded-pill">
      <div class="d-flex align-items-center">
        <img
          class="icon-size"
          src="assets/images/fabIconThunders.svg"
          alt="scremer-thunder"
        />
        <p
          class="fs-18 fw-400 lato-font-family m-0 p-2"
          [class.active-link]="isCurrentRoute('/scream/scream-it')"
        >
          Scream
        </p>
      </div>
    </button>
  </div>
</div>
